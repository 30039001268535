import Quiter from '@/repositories/Axios/Axios';
import store from '@/common/store';
import cookie from 'vue-cookies'
import router from '@/common/routes'

var AxiosErrors = {
  	async error401(error) {
      cookie.set('token_auth',false);
      if (router.currentRoute.path !== '/login') {
        router.replace({ name: 'login' })
      }
      
	  },
  	error404 (error) { 
  	  store.commit('snackbar/showSnackbar',{
        type:'info', 
        duration:400,
        message:'No se han encontrado registros.',
      })
  	},
    errorConnection (error) { 
        store.commit('overlay/hideOverlay')
        
        store.commit('overlay/showOverlay',
          {type:'error',message:'Error de conexión, intente nuevamente.'}
        )
       
    },

  	async getQuiterToken() {
    	try {
    	    var qs = require('qs');
    	    var data_ = qs.stringify({
    	      'grant_type': 'authorization_code',
    	      'client_id': 'hernandezmotores',
    	      'client_secret': '325d47aeae470f2bc358b7ce5f7052e92065bc4f',
    	      'code': '21ca116a378acdf19e9ff780699319ff4cc22c9c' 
    	    });
    	    var config = {
    	      method: 'post',
    	      url:'https://qis.quiter.com/qis/oauth/token',
    	      data : data_,
    	      headers:{
    	        'Accept': 'application/json',
    	        'Content-Type': 'application/x-www-form-urlencoded'
    	      }
    	    };
    	    var token = await axios(config);
    	    return token.data.access_token;
    	} catch (err) {
    	    // Handle Error Here
    	    localStorage.removeItem('quiter-token')
    	    console.error(err);
    	}
	}
}

export default AxiosErrors