export default {
    install(Vue, options) {
        Vue.prototype.$Rules = {
            requiredRules: function(v){
                return !!v || 'Requerido.'
            },         
            emailRules: function(v){
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(v) || 'E-mail invalido.'
            }, 
            // deben tener mas de dos letras para aplicarse rules
            rutRules: function(v){
                if (v && v.length > 3) {
                    var a= this.formatRut(v)
                    return a[1] || 'Rut no valido'
                } else { return true}                
            },
            formatRut (val) {
                let clearRut = typeof val === 'string' ? val.replace(/[^0-9kK]+/g, '').toUpperCase() : '' 
                // limpiamos la variable rut
                if (clearRut.length <= 1) {
                    //return false
                }
                var result = clearRut.slice(-4, -1) + '-' + clearRut.substr(clearRut.length - 1)
                for (var i = 4; i < clearRut.length; i += 3) {
                    result = clearRut.slice(-3 - i, -i) + '.' + result
                }           
                let rut = result            
                if (typeof rut !== 'string') {
                    //return false
                }
                rut = typeof val === 'string' ? val.replace(/[^0-9kK]+/g, '').toUpperCase() : ''
                var rutDigits = parseInt(rut.slice(0, -1), 10)
                var m = 0
                var s = 1
                while (rutDigits > 0) {
                    s = (s + rutDigits % 10 * (9 - m++ % 6)) % 11
                    rutDigits = Math.floor(rutDigits / 10)
                }
                var checkDigit = (s > 0) ? String((s - 1)) : 'K'
                if (checkDigit === rut.slice(-1)) {
                    var Rutvalido = true
                } else {
                    var Rutvalido = false
                }
                if (result == '-') result = ''
                var arr = [result, Rutvalido];
                return arr
            },
        }
    }
}