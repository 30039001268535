export default [
  {
      path: '/login',
      name: 'login',
      component: () => import('@/components/login/Login.vue'),
      meta: {
          hideForAuth: true
      }
  },
  {
      path: '/register',
      name: 'register',
      component: () => import('@/components/login/Register.vue')
  },
  {
      path: '/password/reset/:token',
      name: 'reset-pass',
      component: () => import('@/components/login/reset-pass.vue'),
      meta: {
        requiresAuth: false
      }
  }, 
];