import axios from "axios";
import axiosErrors from '@/repositories/Axios/AxiosErrors';
//import store from '../store'
import store  from '@/common/store';


const baseDomain = "";
const baseURL = `${baseDomain}`;

const apiQuiter = axios.create();

apiQuiter.interceptors.request.use(
  async config => {
    //const access_token = await localStorage.getItem('back-token')  
    //axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content; 
    let token = document.head.querySelector('meta[name="csrf-token"]');
    config.baseURL = baseURL
    config.headers = { 
     // 'X-CSRF-TOKEN':'JTp4ocNI6Ltvy36GVy8qNYErdAGxBoGSDMVePjZU',
      //'lee_carter':token,
      //'Authorization': `${access_token}`,
      //'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Content-Type': 'application/json',
      'accept': 'application/json',
      //'Access-Control-Allow-Methods':'GET,PUT,POST',
    };
    config.timeout = 9000;
    config.withCredentials =  true;
    config.crossDomain = true
    config.meta = config.meta || {}
    config.meta.requestStartedAt = new Date().getTime();
    return config;
  },
  error => {
    Promise.reject(error)
});

// Add a response interceptor
apiQuiter.interceptors.response.use((response) => {
  return {status:response.status,data: response.data}
}, async function (error) {

  //  error timeout y connection
  if (error == 'Error: timeout of 9000ms exceeded' || error == 'Error: Network Error') {
    console.error(' ====> //// error conexion',error)
    axiosErrors.errorConnection(error)
  }
  //  500  error
  if (error.response && error.response.status === 500 || error.response.status === 500) { 
    //axiosErrors.error401(error)
    console.log('//////////////////// error 500')
  }
  //  unauthorized_client
  //if (error.response && error.response.status === 401 || error.response.status === 500) { 
  //  console.error('401 login error',error.response.data.message)
  //  //axiosErrors.error401(error)
  //}
  //  no_records_found
  if(error.response &&  error.response.status === 404){
      axiosErrors.error404(error)
  }

  //  no_records_found
  if(error.response &&  error.response.status === 401){
      axiosErrors.error401(error)
  }
 
  var logData = {
    type: 'axios',
    messege: error.response.data,
    code: error.response.status
  }
  //store.dispatch('fireStore/addLog',logData)
  var response  = {status:error.response.status,data: error.response.data}
  return Promise.reject(response);
});

export default apiQuiter;
