import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/common/store/auth';
import dialog from '@/common/store/dialog';
import snackbar from '@/common/store/snackbar';
Vue.use(Vuex);

export default new Vuex.Store({
    modules:{
        auth,
        dialog,
        snackbar
    },
    state: {
        // lead form fields
        leadFormFields:{},
        // login
        loginMessege:'',
        // loader
        showLoader: false,
        // current path name
        currentPathName:'',
    },
    mutations: {
        // lead form fields
        leadForm(state,data){
            state.leadFormFields = data ||{}
        },

        //login messege 
        loginMessege(state,data){
            state.loginMessege = data
        },

        // loader
        showLoader(state) {
            state.showLoader = true
        },
        hideLoader(state) {
            state.showLoader = false
        },
        // current path name     
        currentPathName(state,data){
            state.currentPathName = data || '/app/crm/reports';
        }
      
    },
    getters: {
        // login messe
        loginMessege(state){
            return state.loginMessege
        },
        // loader
        showLoader: state => {
            return state.showLoader
        },   
        // current path name        
        currentPathName: state => {
            return state.currentPathName
        },    
    },
    actions: {
        //      
    },
});