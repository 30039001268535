import Vue from "vue"
import { DateTime } from "luxon";

Vue.filter("money", val => Intl.NumberFormat("de-DE").format(val))
Vue.filter("porcent", val => { return Math.round(val * 100)})
Vue.filter("dateToWord", val => DateTime.fromSQL(val).setLocale("es").toFormat('dd LLL yyyy'))
Vue.filter("dateTimeToWord", val => DateTime.fromSQL(val).setLocale("es").toFormat('dd LLL yyyy HH:mm'))
Vue.filter("dateTimeGrid", val => {
	if (DateTime.fromISO(val).toFormat('dd LLL yyyy HH:mm') != 'Invalid DateTime') { 
		var date = DateTime.fromISO(val).toFormat('dd LLL yyyy HH:mm')
    	var days = Math.round(DateTime.local().diff(DateTime.fromISO(val), ['days']).values.days)
    	days = Math.sign(days) == '-1' ? days *-1 :days
	}
	if (DateTime.fromSQL(val).toFormat('dd LLL yyyy HH:mm') != 'Invalid DateTime') { 
		var date = DateTime.fromSQL(val).toFormat('dd LLL yyyy HH:mm')
    	var days = Math.round(DateTime.local().diff(DateTime.fromSQL(val), ['days']).values.days)
    	days = Math.sign(days) == '-1'? `+ ${days*-1}`  :days
	}
	
	return  `${date} (${days} días)`
})
Vue.filter("dateTimeGridshort", val => {
	if (DateTime.fromISO(val).toFormat('dd LLL yyyy') != 'Invalid DateTime') { 
		var date = DateTime.fromISO(val).toFormat('dd LLL yyyy')
    	var days = Math.round(DateTime.local().diff(DateTime.fromISO(val), ['days']).values.days)
    	days = Math.sign(days) == '-1' ? days *-1 :days
	}
	if (DateTime.fromSQL(val).toFormat('dd LLL yyyy') != 'Invalid DateTime') { 
		var date = DateTime.fromSQL(val).toFormat('dd LLL yyyy')
    	var days = Math.round(DateTime.local().diff(DateTime.fromSQL(val), ['days']).values.days)
    	days = Math.sign(days) == '-1'? `+ ${days*-1}`  :days
	}
	
	return  `${date} (${days} días)`
})




