<script>
  
  export default {
    methods: {  
      $gridSizeChanged(val){
        return val
      },
      $setRenders(headers){        
        return headers.map( (o) => {
        	var dataRender = this.findRender(o.field)
        	return {...o,cellRendererFramework: dataRender.length == 1 ? dataRender[0].render : null}
        })    
      },   

      findRender(string){
      	var renders = [
          {'name':'number', 'render' : 'leadRender'},
          {'name':'primary_contact.firstname', 'render':'clientRender'},
          {'name':'primary_contact.email', 'render': 'mailRender'},
          {'name':'price', 'render':  'currencyRender'},
          {'name':'status', 'render':  'leadStatusRender'},
          {'name':'created_at', 'render': 'dateTimeRender'},
        ]
        return renders.filter(obj => {
		      return obj.name == string 
		    })
      },
    },
  };
</script>