<script>
  import orderBy from 'lodash/orderBy';
  import groupBy from 'lodash/groupBy';
  import { DateTime } from "luxon"

  export default {
    methods: {
      $getNoDataTableRowsMsj(){
        return 'No hay datos disponibles con los filtros seleccionados.'
      },
      $orderBy(array,field,order='asc') {     
        return  orderBy(array, field, order);
      },
      $getTimeArray(interval){
      	var x = interval; //minutes interval
    		var times = []; // time array
    		var tt = 480; // start time in minutes
    		var et = 1200+1 // end time in munutes
    		var ap = ['AM', 'PM']; // AM-PM
    		
    		//loop to increment the time and push results in array
    		for (var i=0;tt<et; i++) {
    		  var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
    		  var mm = (tt%60); // getting minutes of the hour in 0-55 format
    		  //times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    		  var text = ("" + ((hh==12)?12:hh%12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)];
    		  var value = ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2)
    		  times[i] = {text:text, value:value}
    		  tt = tt + x;
    		}
    		return times
      },
      $getIntervalTimeArray(){
      	return [
           { value:15, text:'15 min'},
           { value:30, text:'30 min'},
           { value:45, text:'45 min'},
           { value:60, text:'1:00 Hr'},
           { value:75, text:'1:15 Hr'},
           { value:90, text:'1:30 Hr'},
           { value:105, text:'1:45 Hr'},
           { value:120, text:'2:00 Hrs'},
         ]
      },
      $getReportDates(range = 6){
        var dt = DateTime.local().setLocale("es")
        var range = [...Array(range).keys()];
        var dates = []
        range.forEach(e => {
          var obj = { value:e, text:this.capitalize(dt.minus({ months: e }).toFormat('LLLL y'))}
          dates.push(obj)
        });
        return dates
      },
      capitalize(s){
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      },
      $chartGroup(data,by){
        return groupBy(data,by);
      },
      $chartGroupByMonth(data){
        console.log('chartGroupBy') 
        Object.entries(data).forEach(entry => {
          const [key, value] = entry;
          console.log('eees',value[0])
          var a = groupBy(value[0], (dt) => 
            DateTime.fromSQL(dt.sent_at).setLocale("es").monthShort
          );
          return a
        })
        return data
      },
      $excelDownload(filename=null,rowDataExcel,headers) {
            import('@/common/Export2Excel').then(excel => {  
              var headersData = headers.forEach( e => { e.label = e.text, e.field = e.value})
              excel.export_json_to_excel({
                data:rowDataExcel,
                header:this.headers,
                filename:this.fileName,
              })
            })
          }, 
    },
  };
</script>