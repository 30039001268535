import axios from 'axios'
import Repository from "@/repositories/RepositoryFactory";
const Backend = Repository.get("posts")
import cookie from 'vue-cookies'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    }
  },

  actions: {
    async signIn ({ dispatch, commit }, credentials, ) {
      var loginResponse = await Backend.login(credentials);
          if (loginResponse.status != 200) {
            commit('loginMessege',loginResponse.data.message, { root: true })
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER', null)
            cookie.set('token_auth',false);
          } 
          if (loginResponse.status == 200) {
            cookie.set('token_auth',true);            
            commit('SET_AUTHENTICATED', true)
            commit('SET_USER', loginResponse.data)
          }
    },

    async signOut ({ dispatch }) {
      await axios.get('/api/logout')
      return '';
    },

    me ({ commit }) {
      //return axios.get('/api/lo').then((res) => {
      //  commit('SET_AUTHENTICATED', true)
      //  commit('SET_USER', res.data)
      //}).catch((error) => {
      //  commit('SET_AUTHENTICATED', false)
      //  commit('SET_USER', null)
      //})
    }
  }
}