export default [
  {
    path: '/app/helpdesk/tickets',
    name:'CRM - Tickets',
    component: () => import(/* webpackChunkName: "lead-detail" */ '@/components/helpdesk/index.vue'),
    children: [
      
      {
        path: '/app/helpdesk/form',
        name:'Soporte Ayuda',
        component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/form.vue')
      },
      //{
      //  path: '/app/helpdesk/tickets/list/:mail',
      //  name:'Tickets Ecofarmacias',
      //  component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/tickets.vue')
      //},
      {
        path: '/app/helpdesk/tickets/:status/list',
        name:'Tickets Ecofarmacias',
        component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/tickets.vue')
      },
    ]
  },
  {
    path: '/app/helpdesk/ticket/:id',
    name:'Ticket Ecofarmacias',
    component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/ticket.vue')
  },

  {
    path: '/app/helpdesk/settings',
    name:'CRM - Tickets ajustes',
    component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/settings.vue')
  },
  
];
