<script>
  
  export default {
    methods: {  
      $setFormModel(val,formSchema){
        const vm = this
        if(typeof val !== 'object') { 
          console.error('error en setFormModel',val)
          return false 
        }
        var result = {}
        var fieldsByGroup = Object.entries(formSchema).map(o=> o[1].properties)
        fieldsByGroup.forEach((item, i) => {
          var values = vm.matchFields(val,Object.keys(item,formSchema))
          result[i]= values
        });

        return result     
      },
      matchFields(val,output){
        var fillForm = {}
        var resultObj={}

        output.forEach((item,i) => {
          var find = Object.entries(val).find((o)=>{            
            if (typeof o[1] === 'object' && o[1] != null){
              var xx = item.split(".");
              return xx[0] == o[0]
            } else {
              return item == o[0]
            }            
          })
          var e = item.split(".")
          
          find = find ? find[1] : '' 
          Object.assign(fillForm, {[item]:find });
        })
        
        Object.entries(fillForm).forEach((item,i) =>{          
          if (item[0].includes(".")) { 
            var e = item[0].split(".")
            var a = Object.entries(item[1]).find(o=> { 
              return e[1] == o[0]
            })            
            Object.assign(resultObj, {[item[0]]:a[1] });            
          }
          else { 
            Object.assign(resultObj, {[item[0]]:item[1] });
          }
        })
        return resultObj
      },
      $setFormSchema(val,formName){
        const vm = this    
        let fields = {
            "type": "object",
            "properties": {}
        } 
        var val = vm.$orderBy(val,'sort_order')
        var sections = {}
        val.forEach( function(value, index, array) {
          var column = value.column
          var inputs = {}         
          value.fields.forEach( function(val, key, array) {
             let backgroundColor={"background-color":"white"}
              inputs[val.field_name] = vm.checkTypeOfInput(val,column)
          })
          sections[index]= {
           "title": value.name,
           "type": "object",
           "properties": {
              ...inputs  
           },
          }          
          Object.assign(fields.properties, sections);
        });

        return fields
      },

      checkTypeOfInput(val,column){
        if (val.type == 'Text') return this.setTextInput(val,column)
        if (val.type == 'Rut') return this.setRutInput(val,column)     
        if (val.type == 'LookUpSystem' && val.field_name.includes("primary_company")) return this.setLookUpInputCompany(val,column)
        if (val.type == 'LookUpSystem' && val.field_name.includes("primary_contact")) return this.setLookUpInputContact(val,column)
        if (val.type == 'LookUpSystem' && val.field_name == "billingstate") return this.setLookUpState(val,column)
        if (val.type == 'LookUpSystem' && val.field_name == "billingcity") return this.setLookUpCity(val,column)
        if (val.type == 'LookUp') return this.setLookUpInput(val,column)
        if (val.type == 'LookUpSystem') return this.setLookUpSystemInput(val,column)
        if (val.type == 'Select') return this.setSelectInput(val,column)        
        if (val.type == 'MultiSelect') return this.setMultiSelectInput(val,column) 
        if (val.type == 'Tag') return this.setTagInput(val,column)              
        if (val.type == 'Textarea') return this.setTextareaInput(val,column)
        if (val.type == 'Currency') return this.setCurrencyInput(val,column)
        if (val.type == 'Integer') return this.setIntegerInput(val,column)
        if (val.type == 'Date') return this.setDateInput(val,column)
        if (val.type == 'Email') return this.setEmailInput(val,column)
        if (val.type == 'Boolean') return this.setCheckBox(val,column)
      },
      setRutInput(val,column){
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-rut",
        }
      },
      setLookUpState(val,column){
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-state",
        }
      },
      setLookUpCity(val,column){
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-city",
        }
      },
      setTextInput(val,column){
        let backgroundColor={'background-color':'white','placeholder':`-- Ingrese  ${ val.label } --`}
        var props = Object.assign(JSON.parse(val.props),backgroundColor)
        return {
          'type':'string',
          "x-class": column == 1 ? '' : 'xs6',
          'title':val.label,
          'x-props':props,
          "x-rules": JSON.parse(val.rules),              
        }
      },
      setLookUpInput(val,column){
        let backgroundColor={"background-color":"white"}
        var data = val && val.field_options.data ? val.field_options.data.map(o => o.label):''
        var obj = {
          "type": "string",
          "x-class": column == 1?'':'xs6',        
          "title":val.label,
          'x-props':Object.assign(JSON.parse(val.props),backgroundColor),
          'x-rules': JSON.parse(val.rules),
          "x-itemTitle": "name",
          "x-itemKey": "id",
          "enum": data,
        }
        return obj
      },
      setLookUpSystemInput(val,column){        
        let backgroundColor={"background-color":"white",'placeholder':`-- Seleccione un ${ val.label } -- `}
        var oneOFvalues = val.field_options.data.map(o=>{ return {const:o.const,title:o.title}})       
        var obj = {
          "type": "string",
          "x-class": column == 1?'':'xs6',        
          "title":val.label,
          'x-props':Object.assign(JSON.parse(val.props),backgroundColor),
          'x-rules': JSON.parse(val.rules),
          "oneOf": oneOFvalues,
          "x-itemTitle": "name",
          "x-itemKey": "id",          
        }
        return obj
      },
      setLookUpInputCompany(val,column){
        let backgroundColor={"background-color":"white",}
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-autcompleteCompany",
        }
      },
      setLookUpInputContact(val,column){
        let backgroundColor={"background-color":"white",}
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-autcompleteContact",
        }
      },
      setSelectInput(val,column){
        let backgroundColor={"background-color":"white"}
        var data = val.field_options.values.length > 0 ? val.field_options.values : []
        var obj = {
          'type':'string',
          "x-class": column == 1?'':'xs6',
          'title':val.label,      
          'x-props':Object.assign(JSON.parse(val.props),backgroundColor),               
          "enum": data
        }
        return  obj
      },      
      setMultiSelectInput(val,column){
        var data = val.field_options.split(",");
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-multiselect", 
          "x-options":{
            'selectProps':{},
          } 
          //"enum": val.field_options.data  
        }
      },
      setTagInput(val,column){
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-tag",
        }
      },
      setTextareaInput(val,column){
        return {
          type: 'string',
          title: val.label,
          'x-display': 'textarea',
          "x-class": column == 1?'':'xs6',  
          'x-props':{"outlined": true,"background-color":'white','placeholder': `-- Ingrese  ${ val.label } --` },
        }
      },
      setIntegerInput(val,column){
        let backgroundColor={"background-color":"white"}
        return {
          "type": "number", 
          'x-props':Object.assign(JSON.parse(val.props),backgroundColor),
          "x-class": column == 1?'':'xs6',
          "title": val.label,  
        }
      },
      setCurrencyInput(val,column){
        return {
          "type": "string",
          "x-class": column == 1?'':'xs6',
          "title": val.label,
          "x-display": "custom-string1",           
        }
      },
      setDateInput(val,column){
        let backgroundColor={'background-color':'white'}
        let placeholder = {'placeholder':`-- Seleccione  ${ val.label } -- `}
        var props = Object.assign(JSON.parse(val.props),backgroundColor)
        props = Object.assign(props,placeholder)
        return {
          "type": 'string',
          "x-class": column == 1 ? '' : 'xs6',
          'x-props':props,
          "title": val.label,
          "format": 'date',                       
        }    
      },
      setEmailInput(val,column){
        let backgroundColor={'background-color':'white','placeholder':' '}
        var props = Object.assign(JSON.parse(val.props),backgroundColor)
        return {
          'type':'string',
          "x-class": column == 1 ? '' : 'xs6',
          'title':val.label,
          'x-props':props,
          "x-rules": ['email'],              
        }        
      },
      setCheckBox(val,column){
       return {
        type: 'boolean',
        title: val.label,
       }
      },
      $setFormSchemaData(val){
        var data = {}
        var val = this.$orderBy(val,'sort_order')
        val.forEach( function(value, index, array) {
          for (const property in value) {        
            var dataObj = {}
            if (typeof value[property] == 'object' && value[property] !== null) {
              console.log('setFormSchemaData',value[property],typeof value[property] == 'object')
              dataObj = {[property]:value[property]['id'] }
             }
            if (Array.isArray(value[property])) dataObj = {[property]:value[property].join(",")}
            if (typeof value[property] != 'object')  dataObj = {[property]:value[property]}  
            if (typeof value[property] == 'object' && value[property] == null)  dataObj = {[property]:null} 
            Object.assign(data, dataObj);
          }
        });
        return data
      },
    },
  };
</script>