export default [
  {
    path: '/app/crm/setting',
    name:'CRM - Configuración',
    component: () => import(/* webpackChunkName: "crm-setup" */ '@/components/crm/setting/crm_setup.vue'),
    children: [
      {
        path: '/app/crm/setting/layouts/:id',
        name:'CRM - Formularios',
        component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/crm/setting/layouts.vue')
      },
      {
        path: '/app/crm/setting/activity-types',
        name:'CRM - Tipos de Actividades',
        component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/crm/setting/activityTypes/list.vue')
      },
      {
        path: '/app/crm/setting/pbm',
        name:'CRM - Beneficios PBM Chile',
        component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/crm/setting/bpm_benefits/index.vue')
      },
    ]
  },
];