<template>
    <v-app >
        <router-view></router-view>
        <v-row no-gutters>
        <!-- snackbar -->
            <v-snackbar
              :timeout="snackbarDuration"
              :color="snackbarColor"              
              top  
              v-if="showSnackbar"          
              v-model="showSnackbar">
              {{ snackbarMessage }}
              <v-icon color="white">{{ snackbarIcon }}</v-icon>
              <v-progress-circular
                v-if="snackbarType == 'loading'"
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-snackbar>            
        </v-row>
    </v-app>
</template>
<script>
    import Vue from 'vue';
    import store from '@/common/store'

    export default {
        //store,
        data(){
            return {
                //showSnackbar
            }
        },
        mounted(){
            //   
        },
        methods: {
            dialogOk() {
                store.commit('dialogOk');
            },
            dialogCancel() {
                store.commit('dialogCancel');
            }
        },
        computed: {
            showLoader() {
                return this.$store.getters.showLoader;
            },
            // Snackbar
            showSnackbar: {
                get() {
                    return this.$store.getters['snackbar/showSnackbar'];
                },
                set(val) {
                    if(!val) this.$store.commit('snackbar/hideSnackbar');
                }
            },
            snackbarMessage() {
                return this.$store.getters['snackbar/snackbarMessage'];
            },
            snackbarColor() {
                return this.$store.getters['snackbar/snackbarColor'];
            },
            snackbarDuration() {
                return this.$store.getters['snackbar/snackbarDuration'];
            },
            snackbarIcon() {
                return this.$store.getters['snackbar/snackbarIcon'];
            },
            snackbarType() {
                return this.$store.getters['snackbar/snackbarType'];
            },            

            // dialog
            showDialog: {
                get() {
                    return store.getters.showDialog;
                },
                set(val) {
                    if(!val) store.commit('snackbar/hideDialog');
                }
            },
            dialogType() {
                return store.getters.dialogType
            },
            dialogTitle() {
                return store.getters.dialogTitle
            },
            dialogMessage() {
                return store.getters.dialogMessage
            },
            dialogIcon() {
                return store.getters.dialogIcon
            },
        },        
    }
</script>
<style lang="scss">
  @import '@/styles/variables.scss';

</style>