export default [
  {
    path: '/app/crm/lead/:uuid/detail',
    name:'CRM - Detalle Lead',
    component: () => import(/* webpackChunkName: "lead-detail" */ '@/components/crm/lead/detail.vue')
  },
  {
    path: '/app/crm/lead/list',
    name:'CRM - Leads',
    component: () => import(/* webpackChunkName: "lead-list" */ '@/components/crm/lead/list.vue')
  },
  {
    path: '/app/crm/lead/create',
    name:'CRM - Crear Lead',
    component: () => import(/* webpackChunkName: "lead-create" */ '@/components/crm/lead/create.vue')
  },
  {
    path: '/app/crm/contact/:uuid/detail',
    name:'CRM - Detalle Contacto',
    component: () => import(/* webpackChunkName: "contact-detail" */ '@/components/crm/contact/detail.vue')
  },
  {
    path: '/app/crm/contact/list',
    name:'CRM - Clientes',
    component: () => import(/* webpackChunkName: "contact-list" */ '@/components/crm/contact/list.vue')
  },
  {
    path: '/app/crm/product/list',
    name:'CRM - Productos',
    component: () => import(/* webpackChunkName: "product-list" */ '@/components/crm/product/list.vue')
  },
  {
    path: '/app/crm/templates',
    name:'CRM - Plantillas',
    component: () => import(/* webpackChunkName: "templates" */ '@/components/crm/templates/template_builder.vue')
    
  }, 
];