import Axios from '@/repositories/Axios/Axios';
const resource = '';

export default {
    get(){
        return 'soy el get';
    },
    async login(credentials){
        Axios.get('/sanctum/csrf-cookie');
        try {
          let res = await Axios.post('api/login', credentials)
          return res;
        } catch (error) {
            return error;
        }   
    }
};