export default {
  namespaced: true,

  state: {
    showSnackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    snackbarDuration: 1500,
    snackbarIcon:'',
    snackbarType:'',
  },

  getters: {
    showSnackbar: state => {
        return state.showSnackbar
    },
    snackbarMessage: state => {
        return state.snackbarMessage
    },
    snackbarColor: state => {
        return state.snackbarColor
    },
    snackbarDuration: state => {
        return state.snackbarDuration
    },
    snackbarIcon: state => {
        return state.snackbarIcon
    },
    snackbarType: state => {
        return state.snackbarType
    },
  },

  mutations: {
    // snackbar
    showSnackbar(state, data) {
        console.log('showSnackbar',state,data)
        state.snackbarDuration = (data.duration*1000) || 1500;                      
        state.showSnackbar = true;
        var message = data.message
        if (data.type == 'success') {
            var icon = 'mdi-check-all'
            var color ='success'
        } else if (data.type == 'info') {
            var icon = 'mdi-information-variant'
            var color = 'orange'
        } else if (data.type == 'error') {
            var icon = 'mdi-alert-box-outline'
            var color = 'error'
        } else if(data.type == 'loading'){
            var color = 'blue-grey darken-3'
            var message = message || 'Cargando ...'
        } else if(data.type == 'mail'){
            var color = 'black'
            var message = message || 'Enviando ...'
        }

        state.snackbarColor = color || 'info';
        state.snackbarIcon = icon;
        state.snackbarMessage = message || 'No message.';
        state.snackbarType = data.type
    },
    hideSnackbar(state) {
        state.showSnackbar = false            
    },
  },

  actions: {
    //
  }
}