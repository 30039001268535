export default [
  {
    path: '/app/tenancy/list',
    name:'Tenants',
    component: () => import('@/components/saas/tenancy/list.vue')
  },
  {
    path: '/app/tenancy/create',
    name:'Create Tenant',
    component: () => import('@/components/saas/tenancy/create.vue')
  },
];