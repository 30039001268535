import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import eS from 'vuetify/src/locale/es.ts' 
import colors from 'vuetify/lib/util/colors'
import minifyTheme from 'minify-css-string'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
/*import { mdiFileExcelOutline, mdiLock, mdiAccount ,mdiPencil, mdiEye, mdiPlus,mdiContentSave,mdiTrashCanOutline ,mdiFilePdf,mdiReload,mdiExitToApp,
mdiMagnify,mdiCheckboxBlankOutline,mdiCheckboxMarked, mdiMinusBox,mdiBlur,mdiDragVertical,mdiEqualBox,mdiChevronRight,mdiCloseCircle,mdiCheckCircle,mdiAlphaTCircleOutline,mdiChevronDown,
mdiDatabaseImport,mdiCalendar,mdiEyeOff,mdiCamera,mdiEmailOutline,mdiPhoneOutline,mdiThumbUpOutline,mdiThumbDownOutline,mdiSendClock
,mdiPaperclip,mdiImageSizeSelectActual,mdiAccountMultiple,mdiTeach,mdiClockOutline,mdiChevronLeft,mdiTune,mdiDatabase,mdiOfficeBuilding,mdiCubeOutline,mdiCalendarCheck,mdiFilterVariant,mdiCogOutline,
 mdiDotsHorizontal,mdiFormatLetterCase,mdiNumeric, mdiFormTextbox, mdiDecimal, mdiCalendarClock, mdiFormSelect, mdiCheckBoxOutline, mdiLinkVariant, mdiPercentOutline,mdiCheckAll , mdiRecordCircleOutline, mdiCurrencyUsd,mdiLockOutline,
 mdiClose,mdiPlusCircleOutline,mdiText,mdiFilterOutline,mdiRefresh,mdiCheckboxMarkedCircle,mdiSkipBackward,mdiSkipForward,mdiSkipPrevious,mdiSkipNext,mdiFilter,mdiTextBoxMultipleOutline,mdiEmailMultiple } from '@mdi/js'
 */
 Vue.use(Vuetify)
Vue.config.productionTip = false;
const opts = {
  theme: {
    themes: {
      //options: { minifyTheme },
      light: {
        primary: colors.blue.darken4,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        btnbar:'#1f62ff',
        btnbarIcon: '#1f62ff',
        formsections:'#ECEFF1',
        greenBar:'#0c8',
        leadAcctionBackground:'#f7f9fc',
        leadBackground:'#e7ecf3',
        crmBackground:'#e7ecf3',
      },
      dark: {
        primary: colors.grey.darken2,
        formsections:'#2c2e30',
      },
    },
  },
	lang: {
      locales: {eS},
      current: 'eS',
  },
  /*
  icons: {
    iconfont: 'md',
    iconfont: 'mdiSvg', // default
  	values: {
  	  user:mdiAccount,
  	  password:mdiLock,
  	  pencil: mdiPencil,
  	  view: mdiEye,
      viewOff: mdiEyeOff,
  	  new: mdiPlus,
      newoutline : mdiPlusCircleOutline,
  	  save: mdiContentSave ,
  	  delete: mdiTrashCanOutline,
  	  load: mdiReload ,
  	  excel: mdiFileExcelOutline,
  	  pdf: mdiFilePdf ,
      exit:mdiExitToApp,
      search:mdiMagnify,
      checkboxOnCircle:mdiCheckboxMarkedCircle,
      checkboxOn:mdiCheckboxMarked,
      checkboxOff:mdiCheckboxBlankOutline,
      checkboxIndeterminate:mdiMinusBox,
      blur:mdiBlur,
      EqualBox:mdiEqualBox,
      ChevronRight :mdiChevronRight,
      next:mdiChevronRight,
      prev:mdiChevronLeft,
      expand:mdiChevronDown,
      dropdown:mdiChevronDown,
      error:mdiCloseCircle,
      camera: mdiCamera,
      success:mdiCheckCircle,
      tenancy:mdiAlphaTCircleOutline,
      DatabaseImport :mdiDatabaseImport,
      calendar:mdiCalendar,
      email:mdiEmailOutline,
      phone:mdiPhoneOutline,
      meeting:mdiAccountMultiple,
      demo:mdiTeach ,
      like:mdiThumbUpOutline,
      unlike:mdiThumbDownOutline , 
      sendClock :mdiSendClock ,
      paperclip :mdiPaperclip ,
      image :mdiImageSizeSelectActual,
      clock:mdiClockOutline ,
      tune:mdiTune ,
      database:mdiDatabase,
      OfficeBuilding:mdiOfficeBuilding,
      cubeOutline :mdiCubeOutline ,
      calendarCheck :mdiCalendarCheck ,
      lead:mdiFilterVariant ,
      cog:mdiCogOutline ,
      dragVertical:mdiDragVertical,
      dotsHorizontal :mdiDotsHorizontal ,
      formatLetterCase :mdiFormatLetterCase ,
      numeric:mdiNumeric,
      formTextbox:mdiFormTextbox,
      decimal :mdiDecimal ,
      calendarClock :mdiCalendarClock ,
      formSelect :mdiFormSelect ,
      checkBoxOutline :mdiCheckBoxOutline ,
      linkVariant :mdiLinkVariant,
      percentOutline :mdiPercentOutline ,
      recordCircleOutline:mdiRecordCircleOutline ,
      currencyUsd:mdiCurrencyUsd,
      checkAll:mdiCheckAll,
      lockOutline:mdiLockOutline ,
      clear:mdiClose,
      note:mdiText,
      filter:mdiFilterOutline ,
      refresh:mdiRefresh,
      skipBackward:mdiSkipBackward ,
      skipForward :mdiSkipForward,
      skipPrevious:mdiSkipPrevious,
      skipNext:mdiSkipNext,
      filer:mdiFilter,
      template:mdiTextBoxMultipleOutline,
      mailing:mdiEmailMultiple,
  	},
  },
  */
    
}

export default new Vuetify(opts)
