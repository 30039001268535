export default [
  {
    path: '/app/crm/reports',
    name:'Reportes',
    component: () => import(/* webpackChunkName: "lead-detail" */ '@/components/crm/reports/dashboard.vue'),
    children: [
      {
        path: '/app/crm/reports/sales-summary',
        name:'Estadisticas ventas',
        component: () => import(/* webpackChunkName: "r-sales-summary" */ '@/components/crm/reports/sales-summary.vue')
      },
      {
        path: '/app/crm/reports/best-sellers',
        name:'Productos más vendidos',
        component: () => import(/* webpackChunkName: "r-best-sellers" */ '@/components/crm/reports/best-sellers-products.vue')
      },
      {
        path: '/app/crm/reports/sales-by-city',
        name:'Ventas por Ciudades',
        component: () => import(/* webpackChunkName: "r-sales-by-city" */ '@/components/crm/reports/sales-by-cities.vue')
      },
      {
        path: '/app/crm/reports/best-contacts',
        name:'Mejores Clientes',
        component: () => import(/* webpackChunkName: "r-best-contacts" */ '@/components/crm/reports/best-clients.vue')
      },
      {
        path: '/app/crm/reports/sales-by-categories',
        name:'Ventas por Categorías',
        component: () => import(/* webpackChunkName: "r-sales-by-cats" */ '@/components/crm/reports/sales-by-categories.vue')
      },
      {
        path: '/app/crm/reports/sales-by-product',
        name:'Ventas por producto',
        component: () => import(/* webpackChunkName: "r-sales-by-cats" */ '@/components/crm/reports/sales-by-product.vue')
      }, 
      {
        path: '/app/crm/reports/sales-own-products',
        name:'Ventas de productos propios',
        component: () => import(/* webpackChunkName: "-sales-own-products" */ '@/components/crm/reports/sales-own-product.vue')
      },
      {
        path: '/app/crm/reports/sales-pbm',
        name:'Ventas PBM',
        component: () => import(/* webpackChunkName: "-sales-pbm" */ '@/components/crm/reports/pbm/sales-by-months.vue')
      },
      {
        path: '/app/crm/reports/sales-coupon',
        name:'Ventas Cupón',
        component: () => import(/* webpackChunkName: "-sales-coupon" */ '@/components/crm/reports/coupons/sales-by-months.vue')
      },
      {
        path: '/app/crm/reports/sales-by-utm',
        name:'Ventas UTM',
        component: () => import(/* webpackChunkName: "sales-utm" */ '@/components/crm/reports/utm-report/sales-by-utm.vue')
      },
      {
        path: '/app/crm/reports/clients-sales',
        name:'Ventas / Clientes',
        component: () => import(/* webpackChunkName: "client-sales" */ '@/components/crm/reports/clients-sales/clients-sales.vue')
      },
      
      
    ]
  },
];