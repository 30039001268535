import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/common/store'
import reports from '@/common/routes/reports';
import settings from '@/common/routes/settings';
import auth from '@/common/routes/auth';
import crm from '@/common/routes/crm';
import mailing from '@/common/routes/mailing';
import tenancy from '@/common/routes/tenancy';
import helpdesk from '@/common/routes/helpdesk';
import cookie from 'vue-cookies'

Vue.use(VueRouter);

let router = new VueRouter({
    mode: "history",
    base:'/',
    routes: [
        ...auth,   
        {
            path: '/',
            name: 'home',
            meta: {
              requiresAuth: true
            },
            component: () => import('@/components/Home.vue'),
            children: [
              ...tenancy,
              ...crm,   
              ...reports,      
              ...settings, 
              ...mailing,
              ...helpdesk,          
            ]
        },
        // routes of ecofarmacia for ticket system
        {
          path: '/app/tickets/list/:mail',
          name:'Tickets Ecofarmacias',
          component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/ticketsPublic.vue')
        },
        {
          path: '/app/tickets/new',
          name:'Tickets Ecofarmacias',
          component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/formPublic.vue'),
            beforeEnter: (to, from,next) => {
               if (window!=window.top) { console.log("I'm in a frame! ")/* I'm in a frame! */ }
                var url = (window.location != window.parent.location)
                ? document.location.hostname
                : document.location.hostname;               
                const domains = ['eco_crm.test', 'ecofarmacias.cl', 'dropp.cl','eco.zizcar.com'];
                 console.log('===>1',url)
              if (!domains.includes(url)) {
                console.log('===>2','No ok')
                return false
              }
              console.log('===>3','ok')
              next()
            },
        },
        {
          path: '/app/ticket/:id',
          name:'Ticket Ecofarmacias',
          component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/ticketPublic.vue')
        },
        {
          path: '/app/complaint-book/list',
          name:'Libro de Reclamos y Sugerencias Ecofarmacias',
          component: () => import(/* webpackChunkName: "crm-layout-setting" */ '@/components/helpdesk/complaint-book.vue')
        },
        
       // { path: "*",  component: () => import('@/utils/PageNotFound.vue') }, 
    ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
     console.log(cookie.get('token_auth'),'====> TOKEN AUTH')
    console.log('beforeEach',to,from,store.state.auth.authenticated)
   // console.log('beforeEach auth cookie',router.app.VueCookies.get('token_auth'))
    if (!cookie.get('token_auth')) {
      console.log('beforeEach 2',router.path)      
      if (router.path !== '/login') {
          next({ name: 'login' })
      }
    } else {
      console.log('beforeEach 3',to,from)
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

router.afterEach(to => {
  //const parsed = JSON.stringify(to.name);
      //localStorage.setItem('cats', parsed);
  localStorage.setItem('LS_ROUTE_KEY', store.state.auth.authenticated);
});
/*
let isFirstTransition = true;

router.beforeEach((to, from, next) => {  
  const lastRouteName = localStorage.getItem(LS_ROUTE_KEY);
  const lastActivityAt = localStorage.getItem(LS_LAST_ACTIVITY_AT_KEY);

  const hasBeenActiveRecently = Boolean(
    lastActivityAt && Date.now() - Number(lastActivityAt) < MAX_TIME_TO_RETURN
  );

  const shouldRedirect = Boolean(
    to.name === "home"
    && lastRouteName
    && isFirstTransition
    && hasBeenActiveRecently
  );

  if (shouldRedirect) next({ name: lastRouteName });
  else next();

  isFirstTransition = false;
});
*/


export default router;

