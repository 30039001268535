export default {
  namespaced: true,

  state: {
    // dialog
    dialogShow: false,
    dialogType: '',
    dialogTitle: '',
    dialogMessage: '',
    dialogIcon: null,
    dialogOkCb: ()=>{},
    dialogCancelCb: ()=>{},
  },

  getters: {
    showDialog: state => {
        return state.dialogShow
    },
    dialogType: state => {
        return state.dialogType
    },
    dialogTitle: state => {
        return state.dialogTitle
    },
    dialogMessage: state => {
        return state.dialogMessage
    },
    dialogIcon: state => {
        return state.dialogIcon
    },
  },

  mutations: {
    showDialog(state, data) {
        state.dialogType = data.type || 'confirm';
        state.dialogTitle = data.title;
        state.dialogMessage = data.message;
        state.dialogIcon = data.icon || null;
        state.dialogOkCb = data.okCb || function(){};
        state.dialogCancelCb = data.cancelCb || function(){};
        state.dialogShow = true;
    },
    hideDialog(state) {
        state.dialogShow = false;
    },
    dialogOk(state) {
        state.dialogOkCb();
        state.dialogShow = false;
    },
    dialogCancel(state) {
        state.dialogCancelCb();
        state.dialogShow = false;
    },
  },

  actions: {
    //
  }
}