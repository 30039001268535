export default [
  {
    path: '/app/mailing/setting',
    name:'Mailing - Configuración',
    component: () => import(/* webpackChunkName: "mail-setting" */ '@/components/mailing/setting/setting.vue')
  },
  {
    path: '/app/mailing/campaign/create',
    name:'Mailing - Nueva Campaña',
    component: () => import(/* webpackChunkName: "mail-campaign-create" */ '@/components/mailing/campaign/create.vue')
  },
  {
    path: '/app/mailing/campaigns',
    name:'Mailing - Campañas',
    component: () => import(/* webpackChunkName: "mail-campaign-list" */ '@/components/mailing/campaign/list.vue')
  },
  {
    path: '/app/mailing/template/:id',
    name:'Mailing - Creador de Plantilla',
    component: () => import(/* webpackChunkName: "templates" */ '@/components/crm/templates/template_builder.vue')
  }, 
];