/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');
import Vue from 'vue';

import vuetify from '@/utils/vuetify' // path to vuetify export
import router from '@/common/routes'
import App from '@/components/App'
import store from '@/common/store'
import helper from '@/utils/helper'
import formUtil from '@/utils/FormUtils'
import listUtil from '@/utils/ag-grid-helpers/listUtils'
import '@/utils/filters.js'

  import Highcharts from "highcharts";
  import HighchartsVue from "highcharts-vue";
  Vue.use(HighchartsVue);



// es2015 module
import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: '1d'})


import Axios from '@/repositories/Axios/Axios';
Vue.prototype.$axios = Axios;

// Set global options
  Highcharts.setOptions({
    lang: {
            loading: 'Cargando...',
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            exportButtonTitle: "Exportar",
            printButtonTitle: "Importar",
            rangeSelectorFrom: "Desde",
            rangeSelectorTo: "Hasta",
            rangeSelectorZoom: "Período",
            downloadPNG: 'Descargar imagen PNG',
            downloadJPEG: 'Descargar imagen JPEG',
            downloadPDF: 'Descargar imagen PDF',
            downloadSVG: 'Descargar imagen SVG',
            printChart: 'Imprimir',
            resetZoom: 'Reiniciar zoom',
            resetZoomTitle: 'Reiniciar zoom',
            thousandsSep: ",",
            decimalPoint: '.'
        },
  });

Vue.mixin(helper);
Vue.mixin(formUtil);
Vue.mixin(listUtil)
import rules from '@/utils/rules.js';
Vue.use(rules);

//Vue.config.productionTip = false


store.dispatch('auth/me').then(() => {
  const app = new Vue({ 	
    vuetify,
    router,
    store,
    rules,
    el: '#app',
    publicPath: '/',
    render: h => h(App),
  })
})

export default app;

